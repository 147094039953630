<template>
<v-container fluid fill-height class="loginOverlay">
    <v-layout flex align-center justify-center>
        <v-flex xs12 sm4 elevation-6>
            <v-toolbar class="pt-5 red darken-4">
                <v-toolbar-title class="white--text"><h4>Rucodel Dashboard</h4></v-toolbar-title>
            </v-toolbar>
            <v-card>
            <v-card-text class="pt-4">
                <div>
                    <v-alert v-if="errors" transition="scale-transition" :value="( errors ? true:false)" color="error">{{ errors }}</v-alert>
                    
                    <v-form v-model="valid" ref="form">
                    <v-text-field
                        label="Coloque o seu login"
                        v-model="email"
                        required
                    ></v-text-field>
                    <v-text-field
                        label="Coloque a sua password"
                        v-model="password"
                        min="8"
                        :append-icon="e1 ? 'visibility' : 'visibility_off'"
                        :append-icon-cb="() => (e1 = !e1)"
                        :type="e1 ? 'password' : 'text'"
                        :rules="passwordRules"
                        counter
                        required
                    ></v-text-field>
                    <v-layout justify-space-between>
                        <v-btn @click="login" :class=" { 'blue darken-4 white--text' : valid, disabled: !valid }">Login</v-btn>
                    </v-layout>
                    </v-form>
                </div>
            </v-card-text>
            </v-card>
        </v-flex>
    </v-layout>
</v-container>
</template>

<script>



export default {
    name: '#app',
    data () {
      return {
        valid: false,
        e1: true,
        password: '',
        passwordRules: [
          (v) => !!v || 'Password is required',
        ],
        errors: '',
        email: '',
        emailRules: [
          (v) => !!v || 'E-mail is required',
          (v) => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(.\w{2,3})+$/.test(v) || 'E-mail must be valid'
        ],
      }
    },
    methods: {
      login () {
        if (this.$refs.form.validate()) {
          //this.$refs.form.$el.submit()
          let login = this.email 
          let password = this.password
          this.errors = ''
          this.$store.dispatch('login', { login, password })
          .then(() => this.$router.push('/'))
          .catch(e => this.errors = e.response.data.message )
        }
      },
      clear () {
        this.$refs.form.reset()
      }
    },
}
</script>